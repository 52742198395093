let $body = document.querySelector( "body" );
let scrollPosition = 0;

setTimeout( function() {
	$body = document.querySelector( "body" );
}, 250 );

export default {
	enable() {
		$body = document.querySelector( "body" );
		scrollPosition = window.pageYOffset;
		$body.classList.add( "scroll-locked" );
		$body.style.overflow = "hidden";
		$body.style.position = "fixed";
		$body.style.top = `-${scrollPosition}px`;
		$body.style.width = "100%";

		if ( $body.classList.contains( "admin-bar" ) && $( window ).width() > 782 ) {
			$body.style.marginTop = "32px";
		} else if (
			$body.classList.contains( "admin-bar" ) &&
			$(window).width() <= 782
		) {
			$body.style.marginTop = "46px";
		} else {
			$body.style.marginTop = "0";
		}
	},
	disable() {
		$body = document.querySelector( "body" );
		$body.classList.remove('scroll-locked');
		$body.style.removeProperty( "overflow" );
		$body.style.removeProperty( "position" );
		$body.style.removeProperty( "top" );
		$body.style.removeProperty( "width" );
		$body.style.removeProperty( "margin-top" );
		window.scrollTo( 0, scrollPosition );
	},
};
