import scrollLock from "../utils/scroll-lock";

$( window ).on( "load", function() {
	// menu open
	$( ".hamburger" ).click( function() {
		$( ".header" ).toggleClass( "menu-open" );
		$( "body" ).toggleClass( "is-nav-active" );

	} );

	// theme mode
	var currentTheme = localStorage.getItem( "theme" );

	if ( currentTheme === "dark" ) {
		$( ".dark-color" ).addClass( "active" );
	}

	function toggleTheme( theme ) {
		$( ".wp-site-blocks a, .nf-field-label" ).css( "transition", "none" );
		$( "body:not(.wp-admin)" ).toggleClass( "dark-mode", theme === "dark" );
		toggleLogo( theme );
		togglePartners( theme );
		localStorage.setItem( "theme", theme );

		setTimeout( function() {
			$( ".wp-site-blocks a, .nf-field-label" ).css( "transition", "" );
		}, 500 );

		if ( theme === "dark" ) {
			$( ".dark-color" ).addClass( "active" );
			$( ".light-color" ).removeClass( "active" );
		} else {
			$( ".light-color" ).addClass( "active" );
			$( ".dark-color" ).removeClass( "active" );
		}
	}
	
	function toggleLogo( theme ) {
		$( "img.light-logo" ).toggle( theme !== "dark" );
		$( "img.dark-logo" ).toggle( theme === "dark" );
	}
	
	function togglePartners( theme ) {
		$( ".partners__list--item .light-image" ).toggle( theme !== "dark" );
		$( ".partners__list--item .dark-image" ).toggle( theme === "dark" );
	}
	
	$( ".dark-color" ).click( function() {
		toggleTheme( "dark" );
	} );
	
	$( ".light-color" ).click( function() {
		toggleTheme( "light" );
	} );

	const savedTheme = localStorage.getItem( "theme" );

	if ( savedTheme ) {
		toggleTheme( savedTheme );
	} else {
		toggleTheme( "light" );
	}
	
	if ( $( window ).scrollTop() > 1 ) {
		$( ".header" ).addClass( "fixed" );
	}

	// fixed header
	$( window ).scroll( function() {
		if ( $( this ).scrollTop() > 1 ) {
			$( ".header" ).addClass( "fixed" );
		} else if ( $( this ).scrollTop() < 1 ) {
			$( ".header" ).removeClass( "fixed" );
		}
	} );

} );

$( window ).on( "load", function() {
	let wpAdminBarHeight = $( "#wpadminbar" ).length ? $( "#wpadminbar" ).outerHeight() : 0;
	let headerHeight = $( ".header" ).length ? $( ".header" ).outerHeight() : 0;
	let pageHash = location.hash;
	
	location.hash = "";
	if ( pageHash[1] !== undefined ) {
		setTimeout( function() {
			$( "html, body" ).animate(
				{scrollTop: $( pageHash ).offset().top - wpAdminBarHeight - headerHeight
				}, 1200 );
			location.hash = pageHash;
		}, 400 );
	}

	$( ".menu-item a" ).on( "click", function() {
		if ( this.hash !== "" ) {
			let hash = this.hash;
			if ( $( hash ).length ) {
				if ( $( "body" ).hasClass( "is-nav-active" ) ) {
	
					scrollLock.disable();
			
					$( "body" ).removeClass( "is-nav-active" );
				
					$( ".header" ).removeClass( "menu-open" );
			
				}
	
				$( "html, body" ).animate( {
					scrollTop: $( hash ).offset().top - wpAdminBarHeight - headerHeight
				}, 2000, function() {
					window.location.hash = hash;
				} );
			}
		}
	} );
} );